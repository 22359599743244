@media (max-width: 991.98px) {
    .navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid, .navbar-expand-lg>.container-sm, .navbar-expand-lg>.container-md, .navbar-expand-lg>.container-lg, .navbar-expand-lg>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    header .logo {
        padding: 0 0 0 0px;
        /*! -webkit-transform: scale(0.75);
        */
        -ms-transform: scale(0.75);
        transform: scale(0.75);
    }
}

@media (max-width: 999px) {
    header .sh {
        position: absolute;
        right: 15%;
        top: 24px;
    }
}

@media (max-width: 767px) {
    header .sh {
        position: relative;
        right: 0;
        top: 0;
    }
}

@media (max-width: 767px) {
    header .sh i {
        font-size: 20px;
    }
}

#upper-menu li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-nav>li {
    padding: 0 10px;
    position: relative;
}

.ml-auto, .mx-auto {
    margin-left: auto !important;
}

header .navbar-nav>li a {
    padding: 15px 10px 15px 10px;
    font-weight: 300;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: #2f2f2f;
    text-decoration: none;
    text-shadow: 0 0 0 #2f2f2f;
    cursor: pointer;
}

/* #upper-menu li:first-of-type a {
    display: inline-block;
    font-weight: 400;
    color: #2f2f2f;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    padding: .57rem 2.8rem;
    line-height: 1.5;
    border-radius: 42px;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    text-transform: uppercase;
    font-family: europa, sans-serif;
    border: 3px solid #f9c930;
}
#upper-menu li:first-of-type a:hover {
    background-color: #f9c930;
} */

.navbar-nav>li a {
    display: block;
    padding: 24px 10px;
    position: relative;
}

header .sh {
    text-decoration: none;
    padding: 4px 0px 0;
    line-height: 1;
    color: #FA0000;
}

header .second-nav {
    background: #2F2F2F;
    color: #fff;
    height: 46px !important;
}

@media (max-width: 999px) {
    header .second-nav {
        display: none;
    }
}

header .second-nav .lower a {
    color: #fff;
    font-size: 15px;
    padding: 11.8px 10px 11.8px 14px;
    margin-bottom: 0px;
    text-shadow: unset;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

header .second-nav .lower a:hover {
    color: #2f2f2f !important;
    background: #fff;
    -webkit-transition: color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out;
}

header .second-nav .lower .show {
    background: #fff;
}

header .second-nav .lower .show a {
    color: #2f2f2f !important;
}

header .second-nav .lower .dropdown-menu {
    text-transform: capitalize;
    height: auto !important;
    top: 100%;
    margin-top: 0;
    background: #fff0;
    border-radius: 0px;
    padding: 20px 20px 50px;
    min-width: 500px !important;
    left: -140%;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
    border: none;
    background: -webkit-gradient(linear, left top, right top, color-stop(12%, #8815ff), color-stop(47%, #e217b7), to(#fa0000)) left bottom #fff no-repeat;
    background: -webkit-linear-gradient(left, #8815ff 12%, #e217b7 47%, #fa0000 100%) left bottom #fff no-repeat;
    background: linear-gradient(to right, #8815ff 12%, #e217b7 47%, #fa0000 100%) left bottom #fff no-repeat;
    background-size: 100% 5px;
    border-top: none;
}

header .second-nav .lower .dropdown-menu .container-sm {
    padding: 0 0 0 0px;
    margin: 0 30px 0 0;
}

header .second-nav .lower .dropdown-menu .row {
    margin: 0px;
}

header .second-nav .lower .dropdown-menu .col-sm-6 {
    margin: 0 0 0 0;
}

header .second-nav .lower .dropdown-menu a {
    color: #2F2F2F !important;
    font-size: 20px;
    font-family: Europa-Regular;
    font-weight: normal;
    text-transform: capitalize;
}

header .second-nav .lower .dropdown-menu a:hover {
    background-color: transparent;
}

header .second-nav .lower .dropdown-menu .head {
    font-family: Europa-Bold;
    padding: 0px 0px;
    margin-bottom: 5px;
}

header .second-nav .lower .dropdown-menu .drop-sub {
    font-size: 16px;
    margin: 0px 0 0 0;
    padding: 7px 0px;
}

@media (max-width: 999px) {
    header .second-nav .lower .dropdown-menu .drop-sub {
        margin: 0 0 0 0px;
    }
}

header .second-nav .lower .dropdown-menu .dropdown-item:hover, header .second-nav .lower .dropdown-menu .dropdown-item:focus {
    background-color: #f8f9fa;
}

.socially {
    background: transparent -webkit-linear-gradient(0deg, #8815FF 0%, #E217B7 53%, #FA0000 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(90deg, #8815FF 0%, #E217B7 53%, #FA0000 100%) 0% 0% no-repeat padding-box;
}

.socially .social-icons {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 10px auto;
}

ul {
    padding: 0;
    list-style: none;
}

.socially .social-icons i {
    font-size: 20px;
    margin: 10px 20px 0px 20px;
    vertical-align: -webkit-baseline-middle;
}

.socially .social-icons i {
    font-size: 20px;
}

.socially a {
    cursor: pointer;
    color: #fff;
    text-decoration: none !important;
}

h3, h5 {
    color: #8815FF;
    text-transform: uppercase;
    font-family: Roboto, sans-serif;
    font-size: 31px;
    font-weight: bold;
    line-height: 39px;
}

.auto-height {
    min-height: 100vh;
}

.filter-bar {
   padding-bottom: 25px;
    background: transparent -webkit-linear-gradient(45deg, #8815FF 0%, #E217B7 53%, #FA0000 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(135deg, #8815FF 0%, #E217B7 53%, #FA0000 100%) 0% 0% no-repeat padding-box;
}

.validation-error {
    font-size: 10px;
    padding: 2px 0 0 0;
}

.validation-error::before {
    content: "ERROR - ";
    color: red;
}

.validation-success {
    font-size: 10px;
    padding: 2px 0 0 0;
}

.validation-success::before {
    content: "SUCCESS - ";
    color: green;
}

.blank-div {
    text-align: center;
    padding: 35px;
    font-size: 35px;
    font-weight: 100;
    color: #cccccc;
    background: #ececec;
}

.numberCircle {
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 0px;
    border-radius: 50%;
    font-weight: 200;
    min-width: 58px;
    min-height: 58px;
}

.numberCircle span {
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
    margin-left: 1px;
    margin-right: 1px;
}

/* Some Back Ground Colors */

.clrGreen {
    background: #51a529;
}

.clrRose {
    background: #e6568b;
}

.clrOrange {
    background: #ec8234;
}

.clrBlueciel {
    background: #21adfc;
}

.clrMauve {
    background: #7b5d99;
}

.clrGray {
    background: #cccccc;
}

.clrWhite {
    background: #ffffff;
}

.forgot-link {
    text-align: left;
    padding: 0 0 10px;
}

.forgot-link:hover {
    text-decoration: underline;
}

.progress-indicator {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex
}

.progress-indicator.stacked {
    display: block
}

.progress-indicator>li {
    -ms-flex: 1;
    -webkit-flex: 1;
    -moz-flex: 1;
    flex: 1
}

.progress-indicator {
    margin: 0 0 1em;
    padding: 0;
    font-size: 80%;
    text-transform: uppercase
}

.progress-indicator>li {
    list-style: none;
    text-align: center;
    width: auto;
    padding: 0;
    margin: 0;
    position: relative;
    text-overflow: ellipsis;
    color: #bbb;
    display: block
}

.progress-indicator>li:hover {
    color: #6f6f6f
}

.progress-indicator>li.completed, .progress-indicator>li.completed .bubble {
    color: #65d074
}

.progress-indicator>li .bubble {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #bbb;
    display: block;
    margin: 0 auto .5em;
}

.progress-indicator>li i {
    position: absolute;
    left: 50%;
    margin-left: -9px;
    color: white;
    z-index: 99;
    font-size: 18px;
    top: 6px;
}

.progress-indicator>li .bubble:after, .progress-indicator>li .bubble:before {
    display: block;
    position: absolute;
    top: 20px;
    width: 100%;
    height: 3px;
    content: '';
    background-color: #bbb
}

.progress-indicator>li.completed .bubble, .progress-indicator>li.completed .bubble:after, .progress-indicator>li.completed .bubble:before {
    background-color: #65d074;
    border-color: #247830
}

.progress-indicator>li .bubble:before {
    left: 0
}

.progress-indicator>li .bubble:after {
    right: 0
}

.progress-indicator>li:first-child .bubble:after, .progress-indicator>li:first-child .bubble:before {
    width: 50%;
    margin-left: 50%
}

.progress-indicator>li:last-child .bubble:after, .progress-indicator>li:last-child .bubble:before {
    width: 50%;
    margin-right: 50%
}

.progress-indicator>li.active .bubble, .progress-indicator>li.active .bubble:after, .progress-indicator>li.active .bubble:before {
    background-color: #edb10a;
    border-color: #5a4304;
}

.progress-indicator>li.active, .progress-indicator>li.active .bubble {
    color: #edb10a;
}

.progress-indicator>li.danger .bubble, .progress-indicator>li.danger .bubble:after, .progress-indicator>li.danger .bubble:before {
    background-color: #d3140f;
    border-color: #440605
}

.progress-indicator>li.danger .bubble {
    color: #d3140f;
}

.progress-indicator>li.danger, .progress-indicator>li.danger .bubble {
    color: #d3140f;
}

@media handheld, screen and (max-width:400px) {
    .progress-indicator {
        font-size: 60%
    }
}

element.style {
    font-weight: 300;
    text-align: center;
}

*, ::after, ::before {
    box-sizing: border-box;
}

user agent stylesheet div {
    display: block;
}


.react-tabs__tab {
    border: none !important;
    color: #808080;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    
}

.react-tabs__tab--selected {
    border-radius: 0 !important;
    color: #000000;
    font-weight: 400;
    border-bottom: 2px solid #000000 !important;
    background: transparent;
}

.react-tabs__tab-list {
    border-bottom: 1px solid#ececec !important;
    
    z-index: 9;
    margin: 0 0 -5px !important;
}

.tab-title {
    font-size: 13px;
    text-transform: uppercase;
}

.react-tabs__tab:focus {
    box-shadow: none !important;
}


.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    
}

.popup_right_content {
    position: absolute;
    background: white;
    right: 0;
    top: 50px;
    bottom: 0;
    padding: 15px;
    width: 800px;
    
}

.popup-right_title {
    font-size: 20px;
}

.popup_content {
    position: absolute;
    background: white;
    left: 30%;
    top: 25%;
    padding: 15px;
    width: 600px;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
                0 2px 4px rgba(0,0,0,0.07), 
                0 4px 8px rgba(0,0,0,0.07), 
                0 8px 16px rgba(0,0,0,0.07),
                0 16px 32px rgba(0,0,0,0.07), 
                0 32px 64px rgba(0,0,0,0.07);
}

.popup-title {
    font-size: 20px;
}

.popup-btn-panel {
    padding-top: 20px;
    float: right;
}

.popup-btn-panel>.btn {
    padding: 5px 10px !important;
}

.summary_label {
    text-transform: uppercase!important;
    font-weight: 300;
    font-size: 0.65em;
    border-bottom: 1px dotted #e5e5e5;
    color:#e5e5e5;
}

.summary_data {
    font-weight: 600;
    font-size: 0.75em;
    color:#ffffff;
}
.shadow-dreamy {
    box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
                0 2px 4px rgba(0,0,0,0.07), 
                0 4px 8px rgba(0,0,0,0.07), 
                0 8px 16px rgba(0,0,0,0.07),
                0 16px 32px rgba(0,0,0,0.07), 
                0 32px 64px rgba(0,0,0,0.07);
}

.shadow-dreamy-small {
    box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
                0 2px 4px rgba(0,0,0,0.07), 
                0 4px 8px rgba(0,0,0,0.07), 
                0 8px 16px rgba(0,0,0,0.07);
}
.required{
    color: #CC0000;
}
.form-label{
    font-weight: 300;
}
.pill-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    border: 3px solid #FFFFFF;
  }
  .pill-left {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100px;
    border: 3px solid #FFFFFF;
  }

  .drawer {
    position: absolute;
    top: 50px;
    right: 0;
    background: #fff;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
    height: 100%;
    overflow-x: hidden;
    padding-top: 0;
    max-width: 240px;
    max-height: 300px;
    min-height: 160px;
    z-index:5;
}
.drawer ul {
    box-sizing: content-box;
    margin: 0;
    padding: 14px 0 0 14px;
    width: 240px;
}
.drawer ul li {
    display: inline-block;
    height: 104px;
    list-style-type: none;
    padding: 6px;
    position: relative;
    transition: transform .2s cubic-bezier(.333,0,0,1);
    vertical-align: top;
    width: 110px;
}
.drawer .app-icon {
    height: 40px;
    transform: scale(.83333);
    text-align: center;
}
.dr-app-name {
    display: block;
    font-size: .8em;
    font-weight: 300;
    line-height: 15px;
    padding-top: 20px;
    text-align: center;
}
.menu-icon{
    cursor: pointer;
}
.react-datepicker-wrapper{
    width: 100%;
}

.flex-table {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 0;
}

.flex-table-row, .flex-table-col-content {
    -webkit-flex: 1;
    -webkit-box-flex: 1;
    -moz-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.flex-table-col {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
}
.btn-group {
    z-index: 0 !important;
}
.download-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100000;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    margin-top: 0.125rem;
    margin-left: 0px;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}
.incr , .decr{
    color:#808080;
    cursor:pointer;
}
.incr:hover {
    color:#00CC00;
}
.decr:hover {
    color:#CC0000;
}
.bc {
    width:100%;
    margin-top:20px;
}
.bc .bc-item{
    position: relative;
}
.bc .bc-item .bc-item-track{
    width:10px;
    position:absolute;
    display:inline-block;
    height:100%;
    margin-top:4px;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: block;
}
.bar {
    height:calc(100% - 20px);
    margin-left: 4px;
    margin-top: 5px;
    margin-bottom: 0px;
    width: 2px;
    background-color: #bbb;
    display: block;
}
.bc-item.complete .dot, .bc-item.complete .bar{
    background-color: #4CAF50;
    border: 1px solid #4CAF50;
}
.bc-item.active .dot{
    background-color: #fff;
    border: 1px solid #333; 
}


.bc-container {
    display:inline-block;
    vertical-align:top;
    margin-left:15px;
    width:100%;
}
.bc-container  .bc-label {
    font-weight:600;
    font-size:0.65em;
    text-transform: uppercase;
}
/* .bc-item.complete .bc-label,
.bc-item.active .bc-label {
    color:#333333;
} */
.bc-item.active .bc-label {
    font-weight:600;
}
.bc-container  .bc-description,
.bc-container  .bc-description-icon  {
    font-weight:300;
    font-size:0.70em;
    padding-bottom: 5px;
    padding-right: 15px;
    vertical-align: top;
    
}
.bc-container  .bc-description-icon .icon {
    width:40px;
    display:inline-block;
    top:0;
}
.bc-container  .bc-description-icon .text {
    display:inline-block;
    width:calc(100% - 40px);
    padding-left:5px;
    top:0;
    vertical-align: top;
}
.btn-clipboard {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: block;
    padding: 0.05rem 0.8rem;
    font-size: .65em;
    color: #bcbcbc;
    background-color: #fff;
    border: 1px solid;
    border-radius: 0.25rem;
    cursor: pointer;
}
.btn-clipboard:hover {
    background-color:#bcbcbc;
    color: #ffffff;
}
.blue-field .form-control,
.blue-field  div[class$='control'] {
    border-color: #003776;
}
.blue-field .input-group-text {
    border-color: #003776;
    background-color:#003776;
    color:#ffffff;
}


.orange-field .form-control,
.orange-field  div[class$='control']  {
    border-color: #ff9300;
}
.orange-field .input-group-text {
    border-color: #ff9300;
    background-color:#ff9300;
    color:#ffffff;
}
.sky-field .form-control {
    border-color: #0096FF;
}
.sky-field .input-group-text {
    border-color: #0096FF;
    background-color:#0096FF;
    color:#ffffff;
}